import React, { useContext } from "react";
import Label from "~/componentes/CustomForm/Label";
import { Select } from "~/componentes/CustomForm";
import ColoredButton from "~/componentes/Button/Styles/ColoredButton";
import SingleValueList from "~/componentes/SingleValueList";
import sistemaListStore from "../../../../../../componentes/SistemaList/ThisMainComponent/thisStore";
import { useState } from "react";
import { useEffect } from "react";
import api from "~/services/api";

import { Container } from "./styles";

function ItensSatisfacaoList({ defaultData, setter, disabled, centroCustos }) {
  const [listCentroCustos, setListCentroCustos] = useState([]);
  const [responsavel, setResponsavel] = useState();
  const [dfSelected, setDfSelected] = useState([]);
  const [dfAvailable, setDfAvailable] = useState([]);
  const [upgradeSetter, setUpgradeSetter] = useState(false);
  const { state } = useContext(sistemaListStore);
  const { edit, editShow } = state?.modal || {};

  useEffect(() => {
    if (centroCustos?.length > 0) {
      setListCentroCustos(centroCustos);
    }
  }, [centroCustos]);

  useEffect(() => {
    if (Array.isArray(defaultData)) {
      setDfSelected((prev) => {
        const newData =
          defaultData.filter((e) => !prev?.find((p) => p?.id === e?.id)) || [];

        return [...prev, ...newData];
      });
    }
  }, [defaultData]);

  useEffect(() => {
    const newData = listCentroCustos.filter((e) => !dfSelected?.find((p) => p?.id === e?.id)) || [];

    setDfAvailable(newData);
  }, [dfSelected, listCentroCustos]);

  useEffect(() => {
    if (dfAvailable.length > 0) {
      setResponsavel(dfAvailable[0]);
    }

  }, [dfAvailable]);

  useEffect(() => {
    if (upgradeSetter === true) {
      setUpgradeSetter(false);
      setter(dfSelected);
    }
  }, [dfSelected, upgradeSetter, setter]);

  function handleAddResponsavel() {
    if (responsavel?.id) {
      const checkResponsavel = dfSelected?.find((e) => e?.id === responsavel?.id);

      if (!checkResponsavel) {
        setDfSelected((prev) => [...prev, responsavel]);
        setUpgradeSetter(true);
      }
    }
  }

  function handleRemoveResponsavel(data) {
    setDfSelected((prev) => {
      const newPrev = [...prev];
      const checkIndex = prev.findIndex((e) => e?.id === data?.id);

      if (checkIndex >= 0) {
        newPrev.splice(checkIndex, 1);
      }

      return newPrev;
    });
    setUpgradeSetter(true);
  }

  return (
    <Container>
      <Label title="Centro de Custo *" />
      {!(editShow && edit) && (
        <div className="Etapa_div_addResponsaveis">
          <Select
            list={{ list: dfAvailable, value: "id", label: "titulo" }}
            setList={setResponsavel}
            firstOption
            defaultValue={responsavel?.id}
            disabled={disabled}
          />
          {!disabled && (
            <ColoredButton small blueLayout type="button" onClick={handleAddResponsavel}>
              Adicionar
            </ColoredButton>
          )}
        </div>
      )}
      <SingleValueList
        data={dfSelected}
        disabled={disabled}
        dataKey="titulo"
        {...{ ...(editShow && edit ? {} : { materialIcon: "close" }) }}
        iconClick={handleRemoveResponsavel}
      />
    </Container>
  );
}

export default ItensSatisfacaoList;
