import React, { memo, useEffect, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import FormStore from "../formStore";
import formHandleOnChange from "../utils/formHandleOnChange";
import formCheckDisabled from "../utils/formCheckDisabled";
import FilterListStore, { FilterListStoreHandleInput } from "~/componentes/FilterList/FilterListStore";
import sistemaListStore from "../../../pages/Sistema/componentes/SistemaList/ThisMainComponent/thisStore";
// import useMenuHandler from "~/pages/Sistema/sistemaHooks/useMenuHandler";

import { Container, InputCustom } from "./style";

function ThisInput(props) {
  const {
    noLoading,
    changeValue,
    isBoolean,
    filter,
    notForm,
    isDate,
    // defaultValue,
    isNumber,
    // value,
    isString,
    name,
    setter,
    allowNull,
    ...rest
  } = props || {};
  const { dispatch: dispatchForm } = React.useContext(FormStore);
  const { dispatch: dispatchFilter } = React.useContext(FilterListStore);
  const { state } = React.useContext(sistemaListStore);
  const { edit, editShow } = state?.modal || {};

  const disableButtons = useSelector((state) => state.disableButtons);
  const [loadedDefaultValue, setLoadedDefaultValue] = useState(false);
  const [loadedValue, setLoadedValue] = useState(false);
  // const [showLateralMenu, setShowLateralMenu] = useState();
  // const { currentMenu } = useMenuHandler({ setShowLateralMenu });

  const handleDataChange = useCallback(
    (thisValue) => {
      if (filter) {
        dispatchFilter &&
          dispatchFilter(
            FilterListStoreHandleInput({
              key: typeof filter === "string" ? filter : name,
              value: thisValue,
            })
          );
      }
      formHandleOnChange(thisValue, props, dispatchForm);
    },
    [dispatchFilter, dispatchForm, filter, name, props]
  );

  useEffect(() => {
    if (!loadedDefaultValue && props?.defaultValue) {
      setLoadedDefaultValue(true);
      handleDataChange(props?.defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.defaultValue, loadedDefaultValue]);

  useEffect(() => {
    if (loadedValue) {
      handleDataChange(props?.value);
    } else if (props?.value) {
      setLoadedValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedValue, props?.value]);

  return (
    <InputCustom
      {...rest}
      name={notForm ? undefined : name}
      disabled={editShow && edit ? true : formCheckDisabled(props, disableButtons)}
      onChange={(e) => {
        !props?.value && handleDataChange(e.target.value);
        props?.onChange && props.onChange(e);
      }}
      // onInput="this.value = this.value.toUpperCase()"
      onKeyPress={(e) => {
        !props?.name && e.key === "Enter" && e.preventDefault();
        props?.onKeyPress && props.onKeyPress(e);
      }}
      // onInputCapture={(e) => e.target.value = e.target.value.toUpperCase() }
    />
  );
}

/**
 * @param {Object} props Aceita todas as props de um componente React.
 * @param {Boolean} props.filter Caso seja uma string, substituirá o name na filtragem. Usado caso esteja envolto do componente FilterList. Se true, será utilizado o name e o value para atualizar a lista do FilterList.
 * @param {Boolean} props.notForm Padrão false. Caso esteja envolto pelo componente Form, o padrão é sempre informar o value caso possua um name. Se passado essa prop, não será passado o valor para o form, independente de ter o name.
 * @param {String} props.placeholder
 * @param {String} props.defaultValue
 * @param {Boolean} props.readOnly
 * @param {Boolean} props.required
 * @param {materialIconNames} props.materialIcon Adiciona um ícone do materialIcon.
 * @param {any} props.value Caso seja passado um value, tanto o setter como o form irão obter o value
 *  informado e não mais o value do onChange. Sendo assim, é necessário passar um onChange caso deseje
 *  um elemento controlado.
 * @param {string} props.name Caso informado um name, o value será enviado para o form como um objeto,
 *  com o name como chave. O name utiliza a notação por pontos, logo, caso utilize por exemplo "pessoa.profissao",
 *  será enviado para o form {pessoa: {profissao: "value"}}.
 * @param {Function} props.onChange Por padrão, já possui um onChange que altera o estado da edição,
 *  Caso seja fornecido outro, ambos serão utilizados.
 * @param {Object} props.setter É usado junto do onChange, porém, já recebe o valor de forma já tratada.
 *  Pode ser fornecido um objeto contendo o setter e o name para adicionar um objeto ao estado do setter.
 *  Ao informar um name, segue o padrão de um form, porém utilizando um setter.
 * @param {Function} props.setter.setter Recebe um setState, que receberá o valor do elemento.
 * @param {string} props.setter.name Transforma o estado em um objeto contendo o name, mesmo padrão do name para Form.
 * @param {Function} props.allowNull Se true, usará dados vazios.
 * @param {Function} props.noLoading Se true, manterá o elemento ativo mesmo que um loading esteja ocorrendo.
 * @param {Function} props.changeValue Recebe como argumento o value, e passará a usar como novo
 *  valor o retorno da função, muito usado para definir um tipo de dado.
 * @param {Boolean} props.isBoolean converterá o value em um Boolean antes de enviar para o form.
 * @param {Boolean} props.isNumber converterá o value em um Number antes de enviar para o form.
 * @param {Boolean} props.isString converterá o value em um String antes de enviar para o form.
 * @param {Boolean} props.isDate converterá o value em um Date antes de enviar para o form.
 * @param {Boolean} props.defaultHtml
 */
function Input(props) {
  const { materialIcon, defaultHtml, ...rest } = props || {};

  const icon = useMemo(() => {
    if (materialIcon) {
      return materialIcon;
    }
  }, [materialIcon]);

  const Element = defaultHtml ? InputCustom : ThisInput;

  return icon ? (
    <Container>
      <i className="material-icons">{icon}</i>
      <Element {...rest} />
    </Container>
  ) : (
    <Element {...rest} />
  );
}

export default memo(Input);
