import React from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";

function TableComponent({ data }) {
  return (
    <>
      <SistemaComponenteInterno>
        <ThisTable data={data} >
          <SLTableCell className="col-w-1" name="id">
            Id
          </SLTableCell>
          <SLTableCell className="col-w-2" name="dispositivo.idAcesso">
            Dispositivo
          </SLTableCell>
          <SLTableCell className="col-w-4 truncate" name="pesquisa.titulo">
            Pesquisa
          </SLTableCell>
          <SLTableCell className="col-w-1" name="dataCadastro">
            Data
          </SLTableCell>
          <SLTableCell className="col-w-3 truncate" name="servico.titulo">
            Serviço
          </SLTableCell>
          <SLTableCell className="col-w-7" name="texto">
            Comentario
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
