import styled from "styled-components";
import CustomScrollBar from "~/componentes/CustomScrollBar";

export const ScrollBarCustom = styled(CustomScrollBar)`
  margin-right: -2em;
  margin-bottom: -2em;
  max-height: calc(100% - 4em) !important;
  height: 100% !important;
  min-height: auto !important;
`;
