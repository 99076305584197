import React from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";

function TableComponent({ unidades, gruposPerfis }) {
  return (
    <>
      <SistemaComponenteInterno>
        <ThisTable>
          <SLTableCell className="col-w-1" name="codigo" inputfilter>
            Id
          </SLTableCell>
          <SLTableCell name="usuario.nome" inputfilter>
            Nome
          </SLTableCell>
          <SLTableCell className="col-w-3" name="usuario.email" inputfilter>
            Email
          </SLTableCell>
          <SLTableCell
            className="col-w-4 text-center"
            name="grupoPerfil.nome"
            selectfilter={{ list: gruposPerfis, value: "nome", outLabel: "Grupo" }}
            inputfilter
          >
            Grupo
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
