import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import IconInsatisfeito from '~/assets/icones/icon_insatisfeito.png';
import IconMuitoInsatisfeito from '~/assets/icones/icon_muito_insatisfeito.png';
import IconSatisfeito from '~/assets/icones/icon_satisfeito.png';
import IconMuitosatisfeito from '~/assets/icones/icon_muito_satisfeito.png';
import IconNormal from '~/assets/icones/icon_muito_normal.png';
import IconValeu from '~/assets/icones/valeu.svg';

import { TextArea } from "~/componentes/CustomForm";
import { ScrollOS, Container, Content, ThisCarregamento } from "./styles";

import { store } from "~/store";
import api from '~/services/api';
import UserDevice from "~/componentes/UserDevice";
import LogoManyVert from "~/assets/logos/LogoManyFlowsVertical.svg";

export default function PesquisaSatisfacao() {
  const dispatch = store.dispatch;
  const [data, setData] = useState();
  const { desktop } = UserDevice();
  let { hash } = useParams();
  const [perguntaAtiva, setPerguntaAtiva] = useState();
  const [perguntaAtivaComentario, setPerguntaAtivaComentario] = useState();
  const [perguntaComentario, setPerguntaComentario] = useState();
  const [perguntaTexto, setPerguntaTexto] = useState();
  const [respostas, setRespostas] = useState([]);
  const [idAtivo, setIdAtivo] = useState(0);
  const [comentario, setComentario] = useState("");
  const [mensagemFinal, setMensagemFinal] = useState(false);
  const [ativoPerg, setAtivoPerg] = useState(true);
  const [cronometro, setCronometro] = useState(0);
  const [cronometroPerg, setCronometroPerg] = useState(0);

  useEffect(() => {
    if (data) {
      if ((idAtivo + 1) <= data?.perguntas?.length) {
        if (data?.perguntas[idAtivo]?.tipo == "TX") {
          setPerguntaTexto(data?.perguntas[idAtivo]);
          setPerguntaComentario(undefined);
          setPerguntaAtiva(data?.perguntas[idAtivo]);
        } else {
          setPerguntaTexto(undefined);
          setPerguntaComentario(undefined);
          setPerguntaAtiva(data?.perguntas[idAtivo]);
        }
      } else if (data?.perguntas?.length == respostas?.length){
        setMensagemFinal(true);
        api.put("/unsigned/dispositivo/pesquisa/resposta", { idDispositivo: hash, respostas })
        .then(response => {
          setRespostas([]);
          setIdAtivo(0);
        });
      }
    }
  }, [data, idAtivo]);

  useEffect(() => {
    api.get("/unsigned/dispositivo/pesquisa/perguntas", { params: { idDispositivo: hash } })
      .then(response => {
        setTimeout(function() {
          setData(response.data);
        }, 2000);
      });
  }, []);

  useEffect(() => {
    if (ativoPerg == false) {
      setTimeout(function() {
        setAtivoPerg(true);
      }, 300);
    }
  }, [ativoPerg]);

  setTimeout(() => {
    if (mensagemFinal) {
      if (cronometro == 3) {
        setCronometro(0);
        setMensagemFinal(false);
      } else {
        setCronometro(cronometro + 1);
      }
    }
  }, 1000);

  return (
    <>
      <Container desktop={desktop}>
        <Content>
          {ativoPerg == false && (
            <div className="carregando">
              <ThisCarregamento></ThisCarregamento>
            </div>
          )}
          {ativoPerg && mensagemFinal == false && !data && (
            <div className="carregando">
              <div>
                <img className={"logo-fundo"} src={LogoManyVert} alt="LogoManyFundo" />
                <p className="titulo">Aguarde...</p>
                <p className="subtitulo">Realizando a busca da pesquisa</p>
                <div className="marcador"></div>
              </div>
            </div>
          )}
          {ativoPerg && mensagemFinal == false && !perguntaAtivaComentario && !perguntaComentario && !perguntaTexto && perguntaAtiva && (
            <div className="pesquisa">
              <div className="header">
                <p className="titulo">{perguntaAtiva?.titulo}</p>
                <p className="subtitulo">Selecione uma das opções abaixo</p>
              </div>
              <div className="itens">
                {perguntaAtiva?.itens?.map((item) => (
                  <div className="item" onClick={() => {
                    let lista = [];
                    for (const itemRespostas of respostas) {
                      lista.push(itemRespostas);
                    }
                    
                    lista.push({
                      idPesquisa: perguntaAtiva?.idPesquisa,
                      pesquisa: perguntaAtiva,
                      idPesquisaPergunta: perguntaAtiva?.id,
                      idPesquisaPerguntaItem: item?.id,
                      comentario: "",
                    });
                    
                    setRespostas(lista);
                    if (perguntaAtiva?.tipo == "CF") {
                      if (item?.estrela == "1") {
                        setPerguntaAtivaComentario(perguntaAtiva?.perguntaComentario);
                      }
                      
                      if (item?.estrela == "2") {
                        setIdAtivo(idAtivo + 1);
                      }
                    } else {
                      setIdAtivo(idAtivo + 1);
                    }
                    setAtivoPerg(false);
                  }}>
                    {
                      perguntaAtiva?.tipo == "SF" && (
                        item?.estrela == "1" ? (
                          <div className="icon">
                            <img src={IconMuitosatisfeito} alt="Vazio" />
                          </div>
                        ) : item?.estrela == "2" ? (
                          <div className="icon">
                            <img src={IconSatisfeito} alt="Vazio" />
                          </div>
                        ) : item?.estrela == "3" ? (
                          <div className="icon">
                            <img src={IconNormal} alt="Vazio" />
                          </div>
                        ) : item?.estrela == "4" ? (
                          <div className="icon">
                            <img src={IconInsatisfeito} alt="Vazio" />
                          </div>
                        ) : (
                          <div className="icon">
                            <img src={IconMuitoInsatisfeito} alt="Vazio" />
                          </div>
                        )
                      )
                    }
                    <p className="titulo">{item?.titulo}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {ativoPerg && mensagemFinal == false && perguntaAtivaComentario && (
            <div className="pesquisa">
              <div className="header">
                <p className="titulo">{perguntaAtivaComentario?.titulo}</p>
                <p className="subtitulo">Obrigatório preencher um comentário</p>
              </div>
              <div className="itens-coment">
                <TextArea 
                  key="comentario"
                  name="comentario" 
                  placeholder="Escreva aqui seu comentário" 
                  required="true" 
                  defaultValue={comentario}
                  onChange={(e) => {
                    const v = e.currentTarget.value;
                    setComentario(v);
                  }}
                />
                <button 
                  className="btn" 
                  onClick={() => {
                    if (comentario != "") {
                      let lista = [];
                      for (const itemRespostas of respostas) {
                        if (itemRespostas?.idPesquisaPergunta == perguntaAtivaComentario?.idPergunta) {
                          lista.push({ ...itemRespostas, comentario: comentario });
                        } else {
                          lista.push(itemRespostas);
                        }
                      }
                      
                      setRespostas(lista);
                      setComentario("");
                      setPerguntaAtivaComentario(undefined);
                      setIdAtivo(idAtivo + 1);
                      setAtivoPerg(false);
                    } else {
                      alert("Comentário é obrigatório")
                    }
                  }}
                >
                  <p>Confirmar</p>
                </button>
              </div>
            </div>
          )}
          {ativoPerg && mensagemFinal == false && !perguntaAtivaComentario && perguntaTexto && (
            <div className="pesquisa">
              <div className="header">
                <p className="titulo">{perguntaTexto?.titulo}</p>
                <p className="subtitulo">Obrigatório preencher um comentário</p>
              </div>
              <div className="itens-coment">
                <TextArea 
                  key="comentario"
                  name="comentario" 
                  placeholder="Escreva aqui seu comentário" 
                  required="true" 
                  defaultValue={comentario}
                  onChange={(e) => {
                    const v = e.currentTarget.value;
                    setComentario(v);
                  }}
                />
                <button 
                  className="btn" 
                  onClick={() => {
                    if (comentario != "") {
                      let lista = [];
                      for (const itemRespostas of respostas) {
                        lista.push(itemRespostas);
                      }
                      
                      lista.push({
                        idPesquisa: perguntaAtiva?.idPesquisa,
                        pesquisa: perguntaAtiva,
                        idPesquisaPergunta: perguntaAtiva?.id,
                        comentario,
                      });
                      
                      setRespostas(lista);
                      setComentario("");
                      setPerguntaTexto(undefined);
                      setIdAtivo(idAtivo + 1);
                      setAtivoPerg(false);
                    } else {
                      alert("Comentário é obrigatório")
                    }
                  }}
                >
                  <p>Confirmar</p>
                </button>
              </div>
            </div>
          )}
          {ativoPerg && mensagemFinal && (
            <div className="valeu">
              <div className="header">
                <p className="titulo">Valeu!</p>
                <p className="subtitulo">Muito Obrigado pelo seu feedback</p>
              </div>
              <div className="itens-coment">
                <img src={IconValeu} alt="Vazio" />
              </div>
            </div>
          )}
        </Content>
      </Container>
    </>
  );
}