import React from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";

function TableComponent() {
  return (
    <>
      <SistemaComponenteInterno>
        <ThisTable>
          <SLTableCell className="col-w-1" name="id" inputfilter>
            Id
          </SLTableCell>
          <SLTableCell className="col-w-2 truncate" name="pesquisa.titulo" inputfilter>
            Pesquisa
          </SLTableCell>
          <SLTableCell className="col-w-2 truncate" name="servico.titulo" inputfilter>
            Turno
          </SLTableCell>
          <SLTableCell className="col-w-3 truncate" name="unidade.nome" inputfilter>
            Unidade
          </SLTableCell>
          <SLTableCell className="col-w-3 truncate" name="centroCusto" inputfilter>
            Centros de Custos
          </SLTableCell>
          <SLTableCell className="col-w-1" name="dataCadastro" inputfilter>
            Data
          </SLTableCell>
          <SLTableCell className="col-w-1" name="horaCadastro" inputfilter>
            Hora
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
