import styled from "styled-components";
import { SLTable } from "~/pages/Sistema/componentes/SistemaList";
import { Colors } from "~/config/Colors";

export const ThisTable = styled(SLTable)`
`;

export const Container = styled.div`

    .no-padd-l {
        padding-left: 0 !important;
    }
    
    .filtro {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin: 1em 0 0em 0;

        .btn-filtro {
            height: 2.1em;
            width: 2.2em;
            padding: 0em;
            font-size: 0.88em;
            font-weight: 600;
            background: ${Colors.Color13};
            border: 0.25em solid ${Colors.Color13};
            border-radius: 0.4em;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
  
            img {
              width: 1em;
            }
        }

        .row {
            width: 100%;
            margin: 0;
        }

        select, input {
            height: 3.5em;
            font-size: 0.63em;
        }

        .basic-multi-select {
            input {
                height: auto !important;
            }

            .select__menu {
                background: ${Colors.Color8} !important;
                color: ${Colors.Color7} !important;
            }

            .select__control {    
                height: 1.9em;
                overflow: auto;
                // min-height: 2em;
                line-height: 0.73em;
                margin-top: 0.1em;
                background: ${Colors.Color8};
                border-color: ${Colors.Color8};

                .select__placeholder {
                    color: ${Colors.Color7} !important;
                    font-size: 0.63em;
                }

                .select__input-container {
                    padding-bottom: 0;
                    padding-top: 0;
                }

                .select__multi-value {
                    background: ${Colors.Color1};

                    .select__multi-value__label {
                        color: ${Colors.Color7} !important;
                    }
                }
            }
        }
    }
`;