export function changeToken(perfil) {
  return {
    type: "@auth/CHANGE_TOKEN",
    payload: { perfil },
  };
}

export function signInRequest(email, senha, noRedirect) {
  return {
    type: "@auth/SIGN_IN_REQUEST",
    payload: { email, senha, noRedirect },
  };
}

export function changePerfis(auth) {
  return {
    type: "@auth/CHANGE_PERFIS",
    payload: { auth },
  };
}

export function signInSucces({ usuario, perfis, perfil, preferencias, idUnidades, controle }) {
  return {
    type: "@auth/SIGN_IN_SUCCESS",
    payload: { usuario, perfis, perfil, preferencias, idUnidades, controle },
  };
}

export function signUpRequest(name, email, senha) {
  return {
    type: "@auth/SIGN_UP_REQUEST",
    payload: { name, email, senha },
  };
}

export function signFailure(options) {
  const { preferencias } = options || {};

  return {
    type: "@auth/SIGN_IN_FAILURE",
    payload: { preferencias },
  };
}

export function signOut() {
  return {
    type: "@auth/SIGN_OUT",
  };
}
