import produce from "immer";
// import CustomToast from "~/componentes/CustomToast";
import api from "~/services/api";

const INITIAL_STATE = {
  perfis: null,
  usuario: null,
  token: null,
  logado: false,
  perfil: null,
  preferencias: false,
  idUnidades: [],
  controle: "U",
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@auth/SIGN_IN_SUCCESS": {
        const { perfis, perfil, usuario, idUnidades, controle } = action.payload || {};
        api.defaults.headers.Authorization = `Bearer ${perfil.token}`;
        draft.perfis = perfis;
        draft.perfil = perfil;
        draft.usuario = usuario;
        draft.idUnidades = idUnidades;
        draft.controle = controle;

        draft.token = perfil.token;
        draft.logado = true;
        draft.preferencias = {
          tipo: perfil?.tipo,
          idPerfil: perfil?.idPerfil,
        };
        break;
      }

      case "@auth/CHANGE_TOKEN": {
        const { perfil } = action.payload || {};
        api.defaults.headers.Authorization = `Bearer ${perfil.token}`;
        draft.token = perfil.token;
        draft.perfil = perfil;
        draft.preferencias = {
          tipo: perfil?.tipo,
          idPerfil: perfil?.idPerfil,
        };
        break;
      }

      case "@auth/SIGN_IN_FAILURE": {
        api.defaults.headers.Authorization = "";
        draft.perfis = null;
        draft.token = null;
        draft.perfil = null;
        draft.usuario = null;
        draft.logado = false;

        if (action?.payload?.preferencias) {
          draft.preferencias = action.payload.preferencias;
        }

        // CustomToast.error(
        //   "Falha ao tentar entrar no sistema. Verifique o email e a senha."
        // );
        break;
      }

      case "@auth/SIGN_OUT": {
        draft.perfis = null;
        draft.token = null;
        draft.perfil = null;
        draft.usuario = null;

        draft.logado = false;
        break;
      }
      default:
    }
  });
}
