import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '~/services/api';
import { Label, Input, Select as SelectCF, SenhaInput } from '~/componentes/CustomForm';
import { Colors } from "~/config/Colors";
import Select, { StylesConfig } from 'react-select';

import ShowConfirmacao from '~/utils/ShowConfirmacao';
import { Container } from './styles';
import { Materialize } from '~/styles/materializeGrid';
import GroupAcoordion from '~/componentes/GroupAcoordion';
import { changePerfis } from '~/store/modules/auth/actions';

function EditContent({ mountedData, unidades }) {
  const dispatch = useDispatch();
  const { auth } = useSelector(state => state);
  const [idUnidades, setIdUnidades] = useState([]);
  const [idUnidadesDefault, setIdUnidadesDefault] = useState([]);
  const [unidadesSelect, setUnidadesSelect] = useState([]);

  const [modulos, setModulos] = useState([]);
  const [gruposPerfis, setGruposPerfis] = useState([]);
  const [tipo, setTipo] = useState(mountedData?.tipo);
  const [ativo, setAtivo] = useState(mountedData?.ativo ? "S" : "N");
  const [grupoPerfil, setGrupoPerfil] = useState(mountedData?.grupoPerfil);
  const [acessos, setAcessos] = useState(typeof mountedData?.acessos == "string" ? JSON.parse(mountedData?.acessos) : mountedData?.acessos);

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    ShowConfirmacao(
      async () => {
        let response = await api.put(
          '/signed/cliente/administracao/gerenciamento/perfil',
          {
            ...data,
            id: mountedData.id,
            tipo,
            idUnidades,
            idGrupoPerfil: grupoPerfil?.id,
            ativo,
          },
        );

        if (response?.status === 200) {
          dispatch(changePerfis(auth));
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao atualizar esse Grupo de Usuário, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja atualizar esse Grupo de Usuário?`,
        successModal: `Grupo de Usuário atualizado com sucesso!`,
      },
    );
  }

  useEffect(() => {
    if (unidades?.length > 0) {
      let lista = [];
      for (const item of unidades) {
        lista.push({  value: item?.id, label: item?.nome, color: "#00B8D9"});
      }
      setUnidadesSelect(lista);
    }
  }, [unidades]);

  useEffect(() => {
    api
      .get('/signed/geral/acessos/check', { params: { tipo: "CLI" } })
      .then(response => setModulos(response.data));
    api
      .get('/signed/cliente/administracao/gerenciamento/gruposperfis', {
        params: { tipo: "CLI", acesso: true },
      })
      .then(response => setGruposPerfis(response.data));
  }, [mountedData]);

  useEffect(() => {
    if (unidadesSelect?.length > 0 && mountedData?.idUnidades?.length > 0) {
      let listaIdsUni = [];
      
      for (const item of mountedData?.idUnidades) {
        const find = unidadesSelect?.find((e) => e?.value == parseInt(item));
        find && listaIdsUni.push(find);
      }

      setIdUnidades(mountedData?.idUnidades);
      setIdUnidadesDefault(listaIdsUni);
    }
  }, [mountedData, unidadesSelect]);
  
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? Colors.Color2
          : undefined,
        color: Colors.Color7,
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : Colors.Color2
            : undefined,
        },
      };
    },
  };

  return (
    <Container onSubmit={handleSubmit} defaultScroll={true}>
      <Materialize className="materialize">
        <div className="row header">
          <p className="h-descricao">
            Preencha as informações corretamente, e se atente ao (*) pois são
            campos obrigatórios
          </p>
        </div>
        <div className="row">
          <Label title="Nome *" className="col s12 m12 l6">
            <Input
              name="nome"
              required="true"
              defaultValue={mountedData?.usuario?.nome}
            />
          </Label>
          <Label title="Email *" className="col s8 m8 l4">
            <Input
              name="email"
              required="true"
              defaultValue={mountedData?.usuario?.email}
            />
          </Label>
          <Label title="Ativo *" className="col s4 m4 l2">
            <SelectCF
              isNumber
              list={{
                list: [
                  { id: 'S', nome: 'Sim' },
                  { id: 'N', nome: 'Não' },
                ],
                value: 'id',
                label: 'nome',
              }}
              name="ativo"
              onChange={e => {
                const v = e?.currentTarget?.value || 'S';
                setAtivo(v);
              }}
              defaultValue={ativo}
            />
          </Label>
        </div>
        <div className="row">
          <Label title="Senha *" className="col s6 m6 l3">
            <SenhaInput
              name="senha"
              required="true"
              defaultValue={mountedData?.usuario?.senha}
            />
          </Label>
          <Label title="Grupo *" className="col s6 m6 l3">
            <SelectCF
              isNumber
              list={{ list: gruposPerfis, value: 'id', label: 'nome' }}
              name="idGrupoPerfil"
              defaultValue={mountedData?.idGrupoPerfil}
              onChange={e => {
                const v = e?.currentTarget?.value || 0;
                const perfil = gruposPerfis.find(o => o?.id === parseInt(v));
                setGrupoPerfil(perfil);
                setAcessos(perfil ? typeof perfil?.acessos == "string" ? JSON.parse(perfil?.acessos) : perfil?.acessos : []);
              }}
              required="true"
            />
          </Label>
          <Label title="Unidades *" className="col s12 m12 l6">
            <Select
              value={idUnidadesDefault}
              isMulti
              name="colors"
              options={unidadesSelect}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Unidades"
              onChange={(e) => {
                let ids = [];
                for (const itemE of e) {
                  ids.push(itemE?.value);
                }
                setIdUnidades(ids);

                let listaIdsUni = [];
                
                for (const item of e) {
                  const find = unidadesSelect.find((o) => o?.value == item?.value);
                  find && listaIdsUni.push(find);
                }
          
                setIdUnidadesDefault(listaIdsUni);
              }}
              styles={colourStyles}
            />
          </Label>
        </div>
        <div className="row">
          <p className="col s12 m12 l12 text-separador">
            Selecione o Tipo e Marque os Acessos do Perfil abaixo:
          </p>
        </div>
        <div className="row">
          {modulos.length > 0 && (
            <GroupAcoordion
              defaultSelect={acessos}
              title={'Acessos '}
              name={'acessos'}
              itens={modulos}
            />
          )}
        </div>
      </Materialize>
    </Container>
  );
}

export default EditContent;
