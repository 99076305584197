import styled from 'styled-components';
import SistemaList from '~/pages/Sistema/componentes/SistemaList';
import { Scrollbars } from 'react-custom-scrollbars';

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

`;

export const LandPage = styled(Scrollbars)`
  width: calc(100%) !important;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    padding: 0em 2em 1em 2em;
  }
`;

export const Container = styled(SistemaList)`
  height: 100%;
  overflow: auto;
`;


// * {
//   box-sizing: border-box;
//   margin: 0;
//   padding: 0;
//   font-family: 'Poppins', sans-serif;
// }
// .home__container {
//   width: 100%;
//   height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// }
// .home__container > * {
//   margin-bottom: 10px;
// }
// .home__header {
//   margin-bottom: 30px;
// }
// .username__input {
//   padding: 10px;
//   width: 50%;
// }
// .home__cta {
//   width: 200px;
//   padding: 10px;
//   font-size: 16px;
//   cursor: pointer;
//   background-color: #607eaa;
//   color: #f9f5eb;
//   outline: none;
//   border: none;
//   border-radius: 5px;
// }
// .chat {
//   width: 100%;
//   height: 100vh;
//   display: flex;
//   align-items: center;
// }
// .chat__sidebar {
//   height: 100%;
//   background-color: #f9f5eb;
//   flex: 0.2;
//   padding: 20px;
//   border-right: 1px solid #fdfdfd;
// }
// .chat__main {
//   height: 100%;
//   flex: 0.8;
// }
// .chat__header {
//   margin: 30px 0 20px 0;
// }
// .chat__users > * {
//   margin-bottom: 10px;
//   color: #607eaa;
//   font-size: 14px;
// }
// .online__users > * {
//   margin-bottom: 10px;
//   color: rgb(238, 102, 102);
//   font-style: italic;
// }
// .chat__mainHeader {
//   width: 100%;
//   height: 10vh;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 20px;
//   background-color: #f9f5eb;
// }
// .leaveChat__btn {
//   padding: 10px;
//   width: 150px;
//   border: none;
//   outline: none;
//   background-color: #d1512d;
//   cursor: pointer;
//   color: #eae3d2;
// }
// .message__container {
//   width: 100%;
//   height: 80vh;
//   background-color: #fff;
//   padding: 20px;
//   overflow-y: scroll;
// }

// .message__container > * {
//   margin-bottom: 10px;
// }
// .chat__footer {
//   padding: 10px;
//   background-color: #f9f5eb;
//   height: 10vh;
// }
// .form {
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }
// .message {
//   width: 80%;
//   height: 100%;
//   border-radius: 10px;
//   border: 1px solid #ddd;
//   outline: none;
//   padding: 15px;
// }
// .sendBtn {
//   width: 150px;
//   background-color: green;
//   padding: 10px;
//   border: none;
//   outline: none;
//   color: #eae3d2;
//   cursor: pointer;
// }
// .sendBtn:hover {
//   background-color: rgb(129, 201, 129);
// }
// .message__recipient {
//   background-color: #f5ccc2;
//   width: 300px;
//   padding: 10px;
//   border-radius: 10px;
//   font-size: 15px;
// }
// .message__sender {
//   background-color: rgb(194, 243, 194);
//   max-width: 300px;
//   padding: 10px;
//   border-radius: 10px;
//   margin-left: auto;
//   font-size: 15px;
// }
// .message__chats > p {
//   font-size: 13px;
// }
// .sender__name {
//   text-align: right;
// }
// .message__status {
//   position: fixed;
//   bottom: 50px;
//   font-size: 13px;
//   font-style: italic;
// }