import React, { useEffect, useState } from "react";
import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import { LandPage, Container, Main } from './styles';
import { Colors } from "~/config/Colors";
import {
  SLEditModal,
  SLCreateModal,
} from "~/pages/Sistema/componentes/SistemaList";
import { useSelector } from "react-redux";
import TableComponent from "./TableComponent";

export default function RelatorioContagem() {
  const [data, setData] = useState([]);
  const [dispositivos, setDispositivos] = useState([]);
  const [dataCadastro, setDataCadastro] = useState();
  const [dispositivoFiltro, setDispositivoFiltro] = useState([]);
  const [ccFiltro, setCCFiltro] = useState([]);
  const [confirmSelects, setConfirmSelects] = useState(false);
  const [primeiraExec, setPrimeiraExec] = useState(true);
  const [loadedData, setLoadedData] = useState(false);
  const [mountedData, setMountedData] = useState();
  const { perfil, idUnidades, controle } = useSelector(state => state.auth);
  const [unidades, setUnidades] = useState([]);

  const [dataDe, setDataDe] = useState(new Date(new Date().getFullYear(), new Date().getMonth()-1, 1).toISOString().slice(0, 10));
  const [dataAte, setDataAte] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10));
  const [pesquisas, setPesquisas] = useState();
  const [idPesquisa, setIdPesquisa] = useState();
  const [centroCustos, setCentroCustos] = useState([]);
  const [idCentroCusto, setIdCentroCusto] = useState([]);
  
  const [idsUnidades, setIdsUnidades] = useState([]);
  const [idUnidadesDefault, setIdUnidadesDefault] = useState([]);
  
  const [servicos, setServicos] = useState();
  const [idServico, setIdServico] = useState([]);

  useEffect(() => {
    setLoadedData(false);
  }, [perfil]);

  useEffect(() => {
    if (confirmSelects) {
      if (dispositivoFiltro == "") {
        setDispositivoFiltro([]);
      }
      setLoadedData(false);
      setConfirmSelects(false);
      setPrimeiraExec(false);
    }
  }, [confirmSelects]);

  useEffect(() => {
    api.get("/signed/cliente/gestorservico/gerenciamento/dispositivos", { params: { ativo: 1, tipo: "A" } })
      .then(response => setDispositivos(response.data));

    api.get("/signed/cliente/gestorservico/gerenciamento/centroCustos", { params: { ativo: 1 } })
      .then(response => {
        let lista = [];
        for (const item of response.data) {
          lista.push({  value: item?.id, label: item?.titulo, color: "#00B8D9"})
        }
        setCentroCustos(lista);
      });

    api.get('/signed/cliente/pesquisa/gerenciamento/pesquisas', { params: { ativo: 1 } })
      .then(response => {
        setPesquisas(response.data);

        if (response.data.length > 0) {
          setIdPesquisa(response.data[0].id);
        }
      });

    api.get('/signed/cliente/gestorservico/gerenciamento/servicos', { params: { ativo: 1 } })
      .then(response => {
        let lista = [];
        for (const item of response.data) {
          lista.push({  value: item?.id, label: item?.titulo, color: "#00B8D9"})
        }
        setServicos(lista);
      });
      
    api.get('/signed/cliente/administracao/organizacao/unidades', { params: { centroCusto: "S" } }).then(response => {
      let lista = [];
      for (const item of idUnidades) {
        const itemFind = response?.data?.find((o) => o?.id == item);
        
        if (itemFind) {
          let listaCC = [];
          for (const itemCC of itemFind?.centroCustos) {
            listaCC.push({ value: itemCC?.id, label: itemCC?.titulo, color: "#00B8D9" });
          }

          lista.push({ value: itemFind?.id, label: itemFind?.nome, color: "#00B8D9", centroCustos: listaCC });
        }
      }
      setUnidades(lista);
      setIdUnidadesDefault([]);
    });
  }, []);

  return (
    <Main>
      <LandPage>
        <div className="content">
          <Container
            requests={{
              findAll: () =>
                api.get('/signed/cliente/pesquisa/relatorio/contagens', {
                  params: { idAcesso: dispositivoFiltro, dataDe, dataAte, idCentroCusto, idServico, idPesquisa, primeiraExec, dataCadastro, idsUnidades }
                }),
              findOne: thisData =>
                api.get('/signed/cliente/pesquisa/relatorio/contagem', {
                  params: { codigoPesquisa: thisData.id },
                }),
            }}
            setter={setData}
            setLoaded={setLoadedData}
            loaded={loadedData}
            informationPage={{
              title: 'Relatório de Pesquisas',
              subTitle: "Visualize o histórico de contagem das pesquisas",
              icone: 'submenu-dispositivo',
            }}
            pageSize={7}
            inputs={[
              { filter: 'id', label: 'Id' },
              { filter: 'dataCadastro', label: 'Data' },
              { filter: 'horaCadastro', label: 'Hora' },
              { filter: 'dispositivo.idAcesso', label: 'Id Dispositivo' },
            ]}
            // selects={[
            //   {
            //     list: unidades,
            //     value: "value",
            //     label: "label",
            //     outLabel: "Todas Unidades",
            //     filter: "idUnidade",
            //   },
            // ]}
          >
            <TableComponent 
              {...{ 
                setPrimeiraExec, 
                pesquisas, 
                idPesquisa, 
                setIdPesquisa,
                centroCustos,
                setIdCentroCusto,
                servicos,
                setIdServico,
                dataDe,
                setDataDe,
                dataAte,
                setDataAte,
                setLoadedData,
                unidades,
                idsUnidades,
                setIdsUnidades,
                idUnidadesDefault,
                setIdUnidadesDefault,
              }} 
            />
            
            <SLEditModal title="Visualizar Resposta" setter={setMountedData}>
              {mountedData && (
                <CreateEditContent {...{ mountedData }} />
              )}
            </SLEditModal>

          </Container>
        </div>
      </LandPage>
    </Main>
  );
}
