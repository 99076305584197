import styled from 'styled-components';
import SistemaList from '~/pages/Sistema/componentes/SistemaList';
import { Scrollbars } from 'react-custom-scrollbars';
import { Colors } from "~/config/Colors";

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

  .col {
    margin: 0 !important;
  }

  .no-padd-l {
    padding-left: 0 !important;
  }

  .filtro {
    margin: 0.6em 0 0.6em 0;

    select, input {
      height: 3.2em;
      font-size: 0.58em;
    }

  }

  .basic-multi-select .select__control {
    margin-top: 0.12em !important;
  }

  .content {
    padding: 0.5em 0.5em 2em 0.5em;

    .materialize {
      padding: 0.5em 0.5em 0em 0.5em;
    }

    .header {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;

      .btn-filtro {
        height: 1.9em;
        width: 5em;
        padding: 0em;
        font-family: 'Nunito', 'cursive';
        min-width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8em;
        font-weight: 500;
        background-color: #FFAB48;
        color: ${Colors.Color2};
        border: 0.25em solid #FFAB48;
        border-radius: 0.625em;
      }
    }
  
    .divTitle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;

      .div-icone {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background: ${Colors.Color2};
        border-radius: 0.4em;
        padding: 0.15em 0.8em 0.15em 0.3em;

        .title {
          font-size: 1.1em;
          font-weight: 700;
          color: ${Colors?.Color3};
        }

        .sub-title {
          font-size: 0.75em;
          font-weight: 500;
          color: ${Colors?.Color15};
        }
      }

      .subTitle {
        margin-top: 0.5em;
        font-size: 0.7125em;
        font-weight: 500;
        color: ${Colors?.Color7};
      }
    }

    .back-section {
      padding: 1.2em 1.2em 1.2em 1.2em;
      background: ${Colors.Color1};
      position: relative;
      
      .section-2 {
        flex-direction: column !important;
      }

      .section-1,
      .section-2 {
        width: 100%;
        padding: 1em;
        border-radius: 0.8em;
        background: ${Colors.Color2};
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .chart-barra {
          width: calc(100% - 20rem);
        }
        
        .chart-total {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;

          .chart-tt-circle {
            width: 20em;
            height: 15em;
            border-radius: 1em;
            background: ${Colors.Color16};
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .ct-percent {
              width: fit-content;
              font-size: 1.2em;
              font-weight: 800;
            }

            .divisor {
              content: "";
              width: 2em;
              height: 0.2em;
              border-radius: 1em;
              margin: 0em 0em 0.2em 0;
            }

            .dv-colo1 {
              background: #2ed3b5;
            }

            .dv-colo2 {
              background: #B6DF53;
            }

            .dv-colo3 {
              background: #FFD147;
            }

            .dv-colo4 {
              background: #FFAB48;
            }

            .dv-colo5 {
              background: #FF5780;
            }

            .ct-title {
              width: fit-content;
              font-size: 0.8em;
              font-weight: 800;
            }
              
            .ct-quant {
              width: fit-content;
              font-size: 1em;
              font-weight: 800;
              color: ${Colors.Color7};
            }
          }
        }
        
        .title {
          width: fit-content;
          padding: 0.4em 0.8em;
          background: ${Colors.Color16};
          color: ${Colors.Color17};
          border-radius: 0.6em;
          font-size: 0.8em;
          font-weight: 800;
          margin-bottom: 1.4em;
        }

        .list {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .item-pergunta {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 0.6em;

            img {
              width: 2rem;
            }

            .titulo-item {
              width: fit-content;
              font-size: 0.7em;
              font-weight: 600;
              color: ${Colors?.Color7};
            }

            .progress {
              width: 100%;
              margin-left: 0.5em;
              border-radius: 1rem;
              background: ${localStorage.getItem("tema") == "E" ? "#15141a" : "#f1f1f1"};
              height: 1em;
            }

            .align-right {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
            }

            .align-left {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
            }

            .align-center {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            }

            .col-1 {
              width: 5rem;
            }
            
            .col-1-1 {
              width: 3rem;
            }
            
            .col-2 {
              width: 10rem;
            }

            .col-3 {
              width: 15rem;
            }

            .col-4 {
              width: 20rem;
            }
            
            .col-5 {
              width: 25rem;
            }

            .col-6 {
              width: 30rem;
            }
          }
        }
      }
    }
  }

`;

export const ContainerComm = styled(SistemaList)`
  height: 100%;
  overflow: auto;
`;

export const LandPage = styled(Scrollbars)`
  width: calc(100%) !important;
`;

export const Container = styled.div``;

export const ThisProgress = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  content: "";
  width: ${props => props?.width};
  height: 0.9em;
  border-radius: 1em;
  background: ${props => props?.backgound};
  animation-name: liq;
  animation-duration: 2s;
  animation-fill-mode: forwards;

  @keyframes liq {
    from {width: 0;}
  }
`;

export const ThisCarregamento = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  content: "";
  width: ${props => props?.cronometro}%;
  height: 0.3em;
  border-radius: 1em;
  position: absolute;
  top: 0;
  left: 0;
  background: ${Colors?.Color23};
  animation: bounce 10s ease-in-out infinite;

  @keyframes bounce {
    from {width: 0%;}
    to {width: 100%;}
  }
`;