import styled from "styled-components";
import { Colors } from "~/config/Colors";

export const Container = styled.div`
  margin-bottom: 0.5em;

  .Etapa_div_addResponsaveis {
    margin-bottom: 0.5em;
    display: flex;
    justify-content: space-between;

    select {
      width: 100%;
      margin-right: 1em;
    }
  }
  
  .list-itens {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .basic-multi-select {
      width: 100%;
    }
    
    .basic-multi-select .select__control {
      margin-top: 0em !important;
    }

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0 !important;
      width: 100%;
      background: ${Colors.Color1};
      border-radius: 0.6em;
      padding: 0.6em;
      margin-bottom: 0.4em !important;
      
      .material-icons {
        font-size: 1.2em !important;
      }

      p {
        font-size: 0.7125em;
        line-height: 0.8em;
        color: ${Colors.Color7};
      }
        
      .no-margin {
        margin: 0 !important;
      }
    }

    .item-icon {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .align-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin: 0;
      // width: 10rem !important;

      input {
        background: ${Colors.Color29} !important;
        height: 1.8em;
        width: 4.5em;
      }

      p {
        width: 15em !important;
        margin-bottom: 0em !important;
        margin-right: 1em;
      }
    }
  }
`;
