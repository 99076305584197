import React, { useEffect, useState } from "react";
import api from "~/services/api";
import { Label, Input, Select, TextArea } from "~/componentes/CustomForm";

import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import ItensCentroCusto from "../ItensCentroCusto";

function CreateEditContent({ mountedData, unidades }) {
  const [ativo, setAtivo] = useState(!mountedData ? "true" : mountedData?.ativo ? "true" : "false");
  const [tipo, setTipo] = useState(mountedData?.tipo || "");
  const [horaInicial, setHoraInicial] = useState(mountedData?.horaInicial ? mountedData?.horaInicial?.split(":")[0] : "");
  const [centroCustos, setCentroCustos] = useState([]);
  const [idUnidade, setIdUnidade] = useState(mountedData?.idUnidade);
  const [idServico, setIdServico] = useState(mountedData?.idServico || 0);
  const [itens, setItens] = useState(mountedData?.servicoCentroCustos || []);
  const [pesquisas, setPesquisas] = useState([]);
  const [servicos, setServicos] = useState([]);

  useEffect(() => {
    api
      .get('/signed/cliente/pesquisa/gerenciamento/pesquisas', { params: { ativo: 1 } })
      .then(response => setPesquisas(response.data));
  }, []);

  useEffect(() => {
    if (idUnidade) {
      api.get('/signed/cliente/gestorservico/gerenciamento/servicos', { params: { ativo: 1, idUnidade, porUnidade: "S" } })
        .then(response => setServicos(response.data));
    }
  }, [idUnidade]);

  useEffect(() => {
    if (unidades?.length > 0) {
      if (!idUnidade) {
        setIdUnidade(unidades[0]?.id);
        setCentroCustos(unidades[0]?.centroCustos);
      } else {
        let itemUnidade = unidades?.find((item) => item?.id == idUnidade);
        if (itemUnidade) {
          setIdUnidade(itemUnidade?.id);
          setCentroCustos(itemUnidade?.centroCustos);
        }
      }
    }
  }, [unidades]);

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let thisData = {
          ...data,
          ...(mountedData?.id ? { id: mountedData?.id } : {}),
          itens,
          tipo,
          idUnidade,
          idServico,
          horaInicial,
        }
        let response;
        if (mountedData?.id)
          response = await api.put("/signed/cliente/pesquisa/email/configjobemail", thisData);
        else
          response = await api.post("/signed/cliente/pesquisa/email/configjobemail", thisData);

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${mountedData?.id ? "atualizar" : "cadastrar"} essa Config de JOB, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja ${mountedData?.id ? "atualizar" : "cadastrar"} essa Config de JOB?`,
        successModal: `Config de JOB ${mountedData?.id ? "atualizada" : "cadastrada"} com sucesso!`,
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit} defaultScroll={true}>
      <Materialize className="materialize">
        <div className="row header">
          <p className="h-descricao">Preencha as informações corretamente, e se atente ao (*) pois são campos obrigatórios</p>
        </div>
        <div className="row">
          <Label title="Título *" className="col s6 m6 l6">
            <Input name="titulo" required="true" defaultValue={mountedData?.titulo} />
          </Label>
          <Label title="Pesquisa *" className="col s2 m2 l4">
            <Select
              isNumber
              list={{ list: pesquisas, value: 'id', label: 'titulo' }}
              name="idPesquisa"
              firstOption
              required="true"
              defaultValue={mountedData?.idPesquisa}
            />
          </Label>
          <Label title="Unidade *" className="col s6 m6 l2">
            <Select
              isString
              list={{ list: unidades, value: "id", label: "titulo" }}
              name="idUnidade"
              defaultValue={idUnidade}
              onChange={(e) => {
                const v = e.currentTarget.value || 0;
                setIdUnidade(v);

                const itemUni = unidades?.find((p) => p?.id == v);
                if (itemUni) {
                  setCentroCustos(itemUni?.centroCustos);
                  setItens([]);
                }
              }}
            />
          </Label>
        </div>
        <div className="row">
          <Label title="Turno *" className="col s2 m2 l2">
            <Select
              isNumber
              list={{ list: servicos, value: 'id', label: 'titulo' }}
              name="idServico"
              firstOption
              required="true"
              defaultValue={idServico}
              onChange={(e) => {
                const v = e.currentTarget.value || 0;
                setIdServico(parseInt(v));
              }}
            />
          </Label>
          <Label title="Tipo *" className="col s2 m2 l2">
            <Select
              isNumber
              list={{
                list: [
                  { id: "H", titulo: "Por Hora" },
                  { id: "D", titulo: "Diário" },
                  { id: "S", titulo: "Semanal" },
                  { id: "M", titulo: "Mensal" },
                ],
                value: 'id',
                label: 'titulo'
              }}
              name="tipo"
              firstOption
              required="true"
              defaultValue={tipo}
              onChange={(e) => {
                const v = e.currentTarget.value;
                setTipo(v);
              }}
            />
          </Label>
          {
            tipo == "H"
              ? (
                <div>
                  <Label title="Hora Inicial *" className="col s2 m2 l2">
                    <Select
                      isNumber
                      list={{
                        list: [
                          { id: "00", titulo: "00:00" },
                          { id: "01", titulo: "01:00" },
                          { id: "02", titulo: "02:00" },
                          { id: "03", titulo: "03:00" },
                          { id: "04", titulo: "04:00" },
                          { id: "05", titulo: "05:00" },
                          { id: "06", titulo: "06:00" },
                          { id: "07", titulo: "07:00" },
                          { id: "08", titulo: "08:00" },
                          { id: "09", titulo: "09:00" },
                          { id: "10", titulo: "10:00" },
                          { id: "11", titulo: "11:00" },
                          { id: "12", titulo: "12:00" },
                          { id: "13", titulo: "13:00" },
                          { id: "14", titulo: "14:00" },
                          { id: "15", titulo: "15:00" },
                          { id: "16", titulo: "16:00" },
                          { id: "17", titulo: "17:00" },
                          { id: "18", titulo: "18:00" },
                          { id: "19", titulo: "19:00" },
                          { id: "20", titulo: "20:00" },
                          { id: "21", titulo: "21:00" },
                          { id: "22", titulo: "22:00" },
                          { id: "23", titulo: "23:00" },
                        ],
                        value: 'id',
                        label: 'titulo'
                      }}
                      name="horaInicial"
                      firstOption
                      required="true"
                      defaultValue={horaInicial}
                      onChange={(e) => {
                        const v = e.currentTarget.value;
                        setHoraInicial(v);
                      }}
                    />
                  </Label>
                  <Label title="Intervalo de Hora *" className="col s2 m2 l2">
                    <Input name="intervalo" type="number" required="true" defaultValue={mountedData?.intervalo} />
                  </Label>
                </div>
              )
              : tipo == "D"
                ? (
                  <div>
                    <Label title="Hora Execução *" className="col s2 m2 l2">
                      <Select
                        isNumber
                        list={{
                          list: [
                            { id: "00", titulo: "00:00" },
                            { id: "01", titulo: "01:00" },
                            { id: "02", titulo: "02:00" },
                            { id: "03", titulo: "03:00" },
                            { id: "04", titulo: "04:00" },
                            { id: "05", titulo: "05:00" },
                            { id: "06", titulo: "06:00" },
                            { id: "07", titulo: "07:00" },
                            { id: "08", titulo: "08:00" },
                            { id: "09", titulo: "09:00" },
                            { id: "10", titulo: "10:00" },
                            { id: "11", titulo: "11:00" },
                            { id: "12", titulo: "12:00" },
                            { id: "13", titulo: "13:00" },
                            { id: "14", titulo: "14:00" },
                            { id: "15", titulo: "15:00" },
                            { id: "16", titulo: "16:00" },
                            { id: "17", titulo: "17:00" },
                            { id: "18", titulo: "18:00" },
                            { id: "19", titulo: "19:00" },
                            { id: "20", titulo: "20:00" },
                            { id: "21", titulo: "21:00" },
                            { id: "22", titulo: "22:00" },
                            { id: "23", titulo: "23:00" },
                          ],
                          value: 'id',
                          label: 'titulo'
                        }}
                        name="horaInicial"
                        firstOption
                        required="true"
                        defaultValue={horaInicial}
                        onChange={(e) => {
                          const v = e.currentTarget.value;
                          setHoraInicial(v);
                        }}
                      />
                    </Label>
                    <Label title="Intervalo de Dia *" className="col s2 m2 l2">
                      <Input name="intervalo" type="number" required="true" defaultValue={mountedData?.intervalo} />
                    </Label>
                  </div>
                )
                : tipo == "S"
                  ? (
                    <div>
                      <Label title="Hora Execução *" className="col s2 m2 l2">
                        <Select
                          isNumber
                          list={{
                            list: [
                              { id: "00", titulo: "00:00" },
                              { id: "01", titulo: "01:00" },
                              { id: "02", titulo: "02:00" },
                              { id: "03", titulo: "03:00" },
                              { id: "04", titulo: "04:00" },
                              { id: "05", titulo: "05:00" },
                              { id: "06", titulo: "06:00" },
                              { id: "07", titulo: "07:00" },
                              { id: "08", titulo: "08:00" },
                              { id: "09", titulo: "09:00" },
                              { id: "10", titulo: "10:00" },
                              { id: "11", titulo: "11:00" },
                              { id: "12", titulo: "12:00" },
                              { id: "13", titulo: "13:00" },
                              { id: "14", titulo: "14:00" },
                              { id: "15", titulo: "15:00" },
                              { id: "16", titulo: "16:00" },
                              { id: "17", titulo: "17:00" },
                              { id: "18", titulo: "18:00" },
                              { id: "19", titulo: "19:00" },
                              { id: "20", titulo: "20:00" },
                              { id: "21", titulo: "21:00" },
                              { id: "22", titulo: "22:00" },
                              { id: "23", titulo: "23:00" },
                            ],
                            value: 'id',
                            label: 'titulo'
                          }}
                          name="horaInicial"
                          firstOption
                          required="true"
                          defaultValue={horaInicial}
                          onChange={(e) => {
                            const v = e.currentTarget.value;
                            setHoraInicial(v);
                          }}
                        />
                      </Label>
                      <Label title="Dia da Semana *" className="col s2 m2 l2">
                        <Select
                          isNumber
                          list={{
                            list: [
                              { id: "1", titulo: "Domingo" },
                              { id: "2", titulo: "Segunda" },
                              { id: "3", titulo: "Terça" },
                              { id: "4", titulo: "Quarta" },
                              { id: "5", titulo: "Quinta" },
                              { id: "6", titulo: "Sexta" },
                              { id: "7", titulo: "Sábado" },
                            ],
                            value: 'id',
                            label: 'titulo'
                          }}
                          name="diaSemana"
                          firstOption
                          required="true"
                          defaultValue={mountedData?.diaSemana}
                        />
                      </Label>
                    </div>
                  )
                  : (
                    <div>
                      <Label title="Hora Execução *" className="col s2 m2 l2">
                        <Select
                          isNumber
                          list={{
                            list: [
                              { id: "00", titulo: "00:00" },
                              { id: "01", titulo: "01:00" },
                              { id: "02", titulo: "02:00" },
                              { id: "03", titulo: "03:00" },
                              { id: "04", titulo: "04:00" },
                              { id: "05", titulo: "05:00" },
                              { id: "06", titulo: "06:00" },
                              { id: "07", titulo: "07:00" },
                              { id: "08", titulo: "08:00" },
                              { id: "09", titulo: "09:00" },
                              { id: "10", titulo: "10:00" },
                              { id: "11", titulo: "11:00" },
                              { id: "12", titulo: "12:00" },
                              { id: "13", titulo: "13:00" },
                              { id: "14", titulo: "14:00" },
                              { id: "15", titulo: "15:00" },
                              { id: "16", titulo: "16:00" },
                              { id: "17", titulo: "17:00" },
                              { id: "18", titulo: "18:00" },
                              { id: "19", titulo: "19:00" },
                              { id: "20", titulo: "20:00" },
                              { id: "21", titulo: "21:00" },
                              { id: "22", titulo: "22:00" },
                              { id: "23", titulo: "23:00" },
                            ],
                            value: 'id',
                            label: 'titulo'
                          }}
                          name="horaInicial"
                          firstOption
                          required="true"
                          defaultValue={horaInicial}
                          onChange={(e) => {
                            const v = e.currentTarget.value;
                            setHoraInicial(v);
                          }}
                        />
                      </Label>
                      <Label title="Dia do Mês *" className="col s2 m2 l2">
                        <Select
                          isNumber
                          list={{
                            list: [
                              { id: "01", titulo: "01" },
                              { id: "02", titulo: "02" },
                              { id: "03", titulo: "03" },
                              { id: "04", titulo: "04" },
                              { id: "05", titulo: "05" },
                              { id: "06", titulo: "06" },
                              { id: "07", titulo: "07" },
                              { id: "08", titulo: "08" },
                              { id: "09", titulo: "09" },
                              { id: "10", titulo: "10" },
                              { id: "11", titulo: "11" },
                              { id: "12", titulo: "12" },
                              { id: "13", titulo: "13" },
                              { id: "14", titulo: "14" },
                              { id: "15", titulo: "15" },
                              { id: "16", titulo: "16" },
                              { id: "17", titulo: "17" },
                              { id: "18", titulo: "18" },
                              { id: "19", titulo: "19" },
                              { id: "20", titulo: "20" },
                              { id: "21", titulo: "21" },
                              { id: "22", titulo: "22" },
                              { id: "23", titulo: "23" },
                              { id: "24", titulo: "24" },
                              { id: "25", titulo: "25" },
                              { id: "26", titulo: "26" },
                              { id: "27", titulo: "27" },
                              { id: "28", titulo: "28" },
                              { id: "29", titulo: "29" },
                              { id: "30", titulo: "30" },
                              { id: "31", titulo: "31" },
                            ],
                            value: 'id',
                            label: 'titulo'
                          }}
                          name="diaMes"
                          firstOption
                          required="true"
                          defaultValue={mountedData?.diaMes?.padStart(2, "0")}
                        />
                      </Label>
                      <Label title="Intervalo *" className="col s2 m2 l2">
                        <Input name="intervalo" required="true" defaultValue={mountedData?.intervalo} />
                      </Label>
                    </div>
                  )
          }
        </div>
        <div className="row">
          <div className="col s6 m6 l6">
            <ItensCentroCusto setter={setItens} defaultData={itens || []} centroCustos={centroCustos} />
          </div>
          <Label title="Emails de Recebimento *" className="col s6 m6 l6">
            <TextArea name="emails" required="true" defaultValue={mountedData?.emails} />
          </Label>
        </div>
      </Materialize>
    </Container>
  );
}

export default CreateEditContent;
