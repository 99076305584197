import styled from "styled-components";
import { Colors } from "~/config/Colors";

export const Container = styled.div`
  display: flex;
  justify-content: center !important;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: ${Colors.Color2};
  color: ${Colors.Color3};

  .button-menu {
    background: ${Colors.Color1};
    display: flex;
    align-items: center;

    @media (max-width: 993px) {
      .identificacao {
        display: flex !important;
      }
    }
  }
    
  .paginacao {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  background: ${Colors.Color1};

  .div-fundo-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0.8em 1em 1em 1em;
  }
  
  .body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: calc(100% - 3.2em);
    width: 100%;
  }

  .body-mobile {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export const TopMenu = styled.div`
  position: relative;
  height: 3.2em;
  width: 100%;
  z-index: ${(props) => props.alerta ? '3' : '4'};
  margin-bottom: 0.9em;

  .fundo-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: ${Colors.Color1};
    width: 100%;
    height: 100%;
    padding: 0 0.8em;

    .lateral-e {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .sec-logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        // width: 14em;

        p {
          font-size: 1.1em;
          color: ${Colors.Color2};
          font-weight: 600;
        }

        .logo {
          width: 6.79em;
        }
      }
    }

    .lateral-d {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 14em;

      .list-modulos {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        margin-right: 0.4em;
      }
    }
  }

  .fundo-bottom {
    background: ${Colors.Color1};
    height: 2.7em;
    width: 100%;
    border-bottom: 2px solid ${localStorage.getItem("tema") == "E" ? "#28242b" : Colors?.Color2};

    .navigation-menu {
      display: flex;
      width: 4em;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex-direction: row;

      .nav {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          width: 1.4em;
          height: 1.6em;
        }
      }
    }
  }
`;

export const ButtonMenu = styled.button`
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5em;
  padding: 0;
  background: ${Colors.Color14};
  border: 0;

  img {
    width: 1.2em;
  }
`;

export const Main = styled.div`
  display: flex;
  justify-content: center;
  z-index: 2;
  background: ${Colors.Color2};
  transition: 0.5s;
  padding: 0em 0em;
  border-radius: 0.8em;

  ${({ showMenuRight, showSubMenu, showSubMenuRight }) => {
    if (showMenuRight) {
      if ((showSubMenu && showSubMenuRight == false) || (showSubMenu == false && showSubMenuRight)) {
        return `
          overflow: initial;
          width: calc(100% - 20.8em);
        `;
      } else if (showSubMenu && showSubMenuRight) {
        return `
          width: calc(100% - 32em);
          overflow: hidden;
        `;
      } else {
        return `
            width: calc(100% - 0em);
            overflow: hidden;
          `;
      }
    } else {
      if (showSubMenu) {
        return `
          overflow: initial;
          width: calc(100% - 16em);
        `;
      } else {
        return `
            width: calc(100% - 4.8em);
            overflow: hidden;
          `;
      }
    }
  }}

  .mainScroll > div:last-child {
    bottom: 24px !important;
    top: 24px !important;
  }

  .basic-multi-select {
    input {
      height: auto !important;
    }

    .select__menu {
      background: ${Colors.Color8} !important;
      color: ${Colors.Color7} !important;
      font-size: 0.7em;
    }

    .select__value-container {
      height: 1.8em;
      overflow: auto;
    }

    .select__control {
      min-height: 1.8em;
      line-height: 0.73em;
      margin-top: 0.5em;
      border-radius: 0.3em;
      background: ${localStorage.getItem("tema") == "E" ? Colors.Color8 : Colors.Color29};
      border-color: ${localStorage.getItem("tema") == "E" ? Colors.Color8 : Colors.Color29};

      .select__placeholder {
        color: ${Colors.Color7} !important;
        font-size: 0.6em;
      }

      .select__input-container {
        padding-bottom: 0;
        padding-top: 0;
      }

      .select__multi-value {
        background: ${Colors.Color1};

        .select__multi-value__label {
          color: ${Colors.Color7} !important;
          font-size: 0.6em;
        }
      }
    }
  }
`;

export const MainScroll = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  height: auto;
  min-height: 100%;
  border-radius: 0.8125em;
  background: ${Colors.Color2};
`;

export const LeftMenu = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  width: ${(props) => props.width};
  height: 100%;
  left: 0;
  z-index: 2;
  background: ${Colors.Color24};
`;

export const LeftModulo = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  width: ${(props) => props.width};
  height: 100%;
  left: 0;
  z-index: 2;
  background: ${Colors.Color2};
`;

export const AcessoButton = styled.button`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 0.9em;
  width: 9em !important;
  padding: 0.33em 0.6em;
  border: 0;
  border-radius: 0.5em;
  ${(props) => (props.ativo ? `background: ${Colors.Color6};` : `background: ${Colors.Color5};`)};

  .img-acesso {
    width: 1.5em;
  }

  .texto-acesso {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 0.6em;

    h5 {
      ${(props) => (props.ativo ? `color: ${Colors.Color1};` : `color: ${Colors.Color1};`)};
      font-size: 0.55em;
      line-height: 0.8em;
      font-weight: 900;
      text-transform: uppercase;
      margin-bottom: -0.2em;
    }

    h6 {
      ${(props) => (props.ativo ? `color: ${Colors.Color2};` : `color: ${Colors.Color7};`)};
      font-size: 0.7em;
      line-height: 1em;
      font-weight: 700;
    }
  }
`;
