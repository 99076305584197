import React, { useEffect, useState } from "react";

import { Container, TopMenu, Content, Main, ButtonMenu, LeftMenu, MainScroll, LeftModulo, ButtonModulo, AcessoButton } from "./styles";

import MenuLista from "./componentes/MenuLista";
import ModuloLista from "./componentes/ModuloLista";
import Perfil from "./componentes/Perfil";

import LogoManyFlow from "~/assets/logos/Logo ManyFlows.svg";
import LogoManyFlowDark from "~/assets/logos/Logo ManyFlow E.svg";
// import Notificacoes from "./componentes/Notificacoes";
// import Logout from "./componentes/Logout";
// import Modal from "~/componentes/Modal";
// import LeaveSystem from "./componentes/LeaveSystem";
import sistemaStore from "./sistemaStore";
import useMenuHandler from "./sistemaHooks/useMenuHandler";
import { useSelector } from "react-redux";

export default function Sistema() {
  const [showLateralMenu, setShowLateralMenu] = useState();
  const [showMaxMenu, setShowMaxMenu] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showMenuWithSubMenu, setShowMenuWithSubMenu] = useState();
  const [showSubMenuRight, setShowSubMenuRight] = useState(false);
  const [showMenuWithSubMenuRight, setShowMenuWithSubMenuRight] = useState();
  const [showModulo, setShowModulo] = useState(false);
  const [ativaAnterior, setAtivaAnterior] = useState(true);
  const [ativaProximo, setAtivaProximo] = useState(true);
  const [modulosAcessoLeft, setModulosAcessoLeft] = useState([]);
  const [modulosAcessoTop, setModulosAcessoTop] = useState([]);
  const {
    desktop,
    modulosAcesso,
    componentPath,
    setComponentPath,
    RenderedComponent,
    currentModulo,
    currentMenu,
    currentSubMenu,
    perfil,
    idUnidades,
    controle,
  } = useMenuHandler({ setShowLateralMenu });

  const { errorModal, loadingModal, confirmacaoModal, successModal } =
    useSelector((state) => state) || {};

  useEffect(() => {
    let modulosLeft = [];
    let modulosTop = [];
    for (let item of modulosAcesso) {
      if (item?.tipoMenu === "LEFT") modulosLeft.push(item);
      else if (item?.tipoMenu === "TOP") modulosTop.push(item);
    }

    setModulosAcessoLeft(modulosLeft);
    setModulosAcessoTop(modulosTop);
  }, [modulosAcesso]);

  useEffect(() => {
    if (currentModulo?.tipoMenu === "TOP") {
      setShowMenu(false);
    } else if (currentModulo?.tipoMenu === "LEFT") {
      setShowMenu(true);
    }
  }, [currentModulo]);

  return (
    <Container desktop={desktop}>
      {modulosAcessoLeft &&
        <LeftModulo
          width={showMenu ? "3.5em" : "0em"}
          showLateralMenu={showLateralMenu}
        >
          <ModuloLista
            {...{
              desktop,
              modulos: modulosAcessoLeft,
              setComponentPath,
              currentModulo,
              currentMenu,
              currentSubMenu,
              showMaxMenu,
              setShowMaxMenu,
              showSubMenu,
              setShowSubMenu,
              showMenuWithSubMenu,
              setShowMenuWithSubMenu,
              perfilAtivo: perfil,
            }}
          />
        </LeftModulo>
      }
      <Content desktop={desktop}>
        <div className="div-fundo-content">
          <TopMenu
            alerta={errorModal?.ativo || successModal?.ativo || loadingModal || confirmacaoModal?.ativo}
            desktop={desktop}
            widthSombra={"10.6em"/*currentModulo?.menus?.length > 8 ? "10.6em" : "4.8em"*/}
          >
            <div className="sombra"></div>
            <div className="fundo-top">
              <div className="lateral-e">
                <div className="sec-logo">
                  <img src={localStorage.getItem("tema") == "E" ? LogoManyFlowDark : LogoManyFlow} alt="LogoManyFlow" />
                </div>
              </div>
              <div className="lateral-d">
                <div className="list-modulos">
                  {/* {modulosAcessoLeft?.map((modulo, index) => (
                    <AcessoButton
                      clean
                      key={index}
                      ativo={currentModulo?.id === modulo?.id ? 1 : undefined}
                      onClick={() => {
                        let thisModulo = modulo;
                        let thisMenu = thisModulo?.menus?.[0];

                        if (!thisMenu.possuiSubMenu) {
                          setComponentPath({ moduloPath: thisModulo?.path, menuPath: thisMenu?.path, subMenuPath: false });
                        } else {
                          let thisSubMenu = thisMenu?.subMenus?.[0];
                          setComponentPath({ moduloPath: thisModulo?.path, menuPath: thisMenu?.path, subMenuPath: thisSubMenu?.path });
                        }
                        setShowMaxMenu(true);
                      }}
                    >
                      <img className="img-acesso" src={require('~/assets/modulos/' + (currentModulo?.id === modulo?.id ? "ativo/" : "desativo/") + modulo?.icone + '.svg')} alt="Acesso" />
                      <div className="texto-acesso">
                        <h5 title="Descrição">Módulo</h5>
                        <h6 title="Empresa Gestor">{modulo?.nome}</h6>
                      </div>
                    </AcessoButton>
                  ))} */}
                </div>
                {/* <ButtonMenu>
                <img src={IconTopInicial} alt="Inicial" />
              </ButtonMenu> */}
                <ButtonMenu onClick={() => {
                  if (localStorage.getItem("tema")) {
                    if (localStorage.getItem("tema") == "E") {
                      localStorage.setItem("tema", "C");
                    } else {
                      localStorage.setItem("tema", "E");
                    }
                  } else {
                    localStorage.setItem("tema", "E");
                  }
                  window.location.reload();
                }}>
                  <img src={require('~/assets/icones/' + (localStorage.getItem("tema") == "C" ? "IconLightMode" : "IconDarkMode") + '.svg')} alt={"Tema"} />
                </ButtonMenu>
                {/* <ButtonMenu>
                <img src={IconTopNotificacao} alt="Notificação" />
              </ButtonMenu>
              <ButtonMenu>
                <img src={IconTopAgenda} alt="Agenda" />
              </ButtonMenu>
              <ButtonMenu>
                <img src={IconTopCompCliente} alt="Complemento do Cliente" />
              </ButtonMenu> */}
                <Perfil {...{
                  desktop,
                  perfilAtivo: perfil,
                  modulos: modulosAcessoLeft,
                  setComponentPath,
                  currentModulo,
                  showMaxMenu,
                  setShowMaxMenu,
                }} />
              </div>
            </div>
          </TopMenu>
          <div className={desktop ? "body" : "body-mobile"}>
            {desktop && showMenu
              ? (
                <LeftMenu
                  width={showMaxMenu ? "14em" : "0em"}
                  showLateralMenu={showLateralMenu}
                >
                  <MenuLista
                    {...{
                      desktop,
                      modulos: modulosAcessoLeft,
                      setComponentPath,
                      currentModulo,
                      currentMenu,
                      currentSubMenu,
                      showMaxMenu,
                      setShowMaxMenu,
                      showSubMenu,
                      setShowSubMenu,
                      showMenuWithSubMenu,
                      setShowMenuWithSubMenu,
                      perfilAtivo: perfil,
                    }}
                  />
                </LeftMenu>
              )
              : (
                <LeftMenu
                  width={"0em"}
                  showLateralMenu={showLateralMenu}
                >
                </LeftMenu>
              )
            }
            <Main
              desktop={desktop}
              showSubMenu={showSubMenu}
              showSubMenuRight={showSubMenuRight}
              showMenuRight={true/*currentModulo?.menus?.length > 8*/}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={500}
            >
              <MainScroll className="mainScroll">
                {RenderedComponent && (
                  <sistemaStore.Provider
                    value={{
                      ...componentPath,
                      setComponentPath: ({ moduloPath, menuPath, subMenuPath, props }) => {
                        setComponentPath({ moduloPath, menuPath, subMenuPath, props });
                      },
                    }}
                  >
                    <RenderedComponent {...{ showMaxMenu, setShowMaxMenu, desktop, idUnidades, controle }} />
                  </sistemaStore.Provider>
                )}
              </MainScroll>
            </Main>
          </div>
        </div>
      </Content>
    </Container>
  );
}
