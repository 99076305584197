import React, { useEffect, useState } from 'react';
import api from '~/services/api';
import { Label, Input, Select as SelectCF, SenhaInput } from '~/componentes/CustomForm';
import { Colors } from "~/config/Colors";
import Select, { StylesConfig } from 'react-select';

import { Container } from './styles';
import { Materialize } from '~/styles/materializeGrid';
import ShowConfirmacao from '~/utils/ShowConfirmacao';
import GroupAcoordion from '~/componentes/GroupAcoordion';
import FindOrCreateUser from '~/componentes/FindOrCreateUser';

function CreateContent({ unidades }) {
  const [idUnidades, setIdUnidades] = useState([]);
  const [unidadesSelect, setUnidadesSelect] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [gruposPerfis, setGruposPerfis] = useState([]);
  const [usuario, setUsuario] = useState();
  const [tipo, setTipo] = useState('CLI');
  const [grupoPerfil, setGrupoPerfil] = useState(undefined);
  const [ativo, setAtivo] = useState("S");

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let response;

        response = await api.post(
          '/signed/cliente/administracao/gerenciamento/perfil',
          {
            ...data,
            usuario,
            tipo,
            ativo,
            idUnidades,
            idGrupoPerfil: grupoPerfil?.id,
          },
        );

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao cadastrar esse Perfil, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja cadastrar esse Perfil?`,
        successModal: `Perfil cadastrado com sucesso!`,
      },
    );
  }

  useEffect(() => {
    api
      .get('/signed/geral/acessos/check', { params: { tipo: "CLI" } })
      .then(response => setModulos(response.data));
    api
      .get('/signed/cliente/administracao/gerenciamento/gruposperfis', {
        params: { tipo: "CLI", acesso: true },
      })
      .then(response => setGruposPerfis(response.data));
  }, []);

  useEffect(() => {
    if (unidades?.length > 0) {
      let lista = [];
      for (const item of unidades) {
        lista.push({  value: item?.id, label: item?.nome, color: "#00B8D9"})
      }
      setUnidadesSelect(lista);
    }
  }, [unidades]);

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? Colors.Color2
          : undefined,
        color: Colors.Color7,
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : Colors.Color2
            : undefined,
        },
      };
    },
  };

  return (
    <Container onSubmit={handleSubmit} defaultScroll={true}>
      <Materialize className="materialize">
        <div className="row header">
          <p className="h-descricao">
            Preencha as informações corretamente, e se atente ao (*) pois são
            campos obrigatórios
          </p>
        </div>
        <div className="row">
          <Label title="Nome *" className="col s12 m12 l6">
            <Input
              name="nome"
              required="true"
            />
          </Label>
          <Label title="Email *" className="col s8 m8 l4">
            <Input
              name="email"
              required="true"
            />
          </Label>
          <Label title="Ativo *" className="col s4 m4 l2">
            <SelectCF
              isNumber
              list={{
                list: [
                  { id: 'S', nome: 'Sim' },
                  { id: 'N', nome: 'Não' },
                ],
                value: 'id',
                label: 'nome',
              }}
              name="ativo"
              onChange={e => {
                const v = e?.currentTarget?.value || 'S';
                setAtivo(v);
              }}
              defaultValue={ativo}
            />
          </Label>
        </div>
        <div className="row">
          <Label title="Senha *" className="col s6 m6 l3">
            <SenhaInput
              name="senha"
              required="true"
            />
          </Label>
          <Label title="Grupo *" className="col s6 m6 l3">
            <SelectCF
              isNumber
              list={{ list: gruposPerfis, value: 'id', label: 'nome' }}
              name="idGrupoPerfil"
              onChange={e => {
                const v = e?.currentTarget?.value || 0;
                setGrupoPerfil(gruposPerfis.find(o => o?.id === parseInt(v)));
              }}
              firstOption
              required="true"
            />
          </Label>
          <Label title="Unidades *" className="col s12 m12 l6">
            <Select
              defaultValue={unidadesSelect}
              isMulti
              name="colors"
              options={unidadesSelect}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Unidades"
              onChange={(e) => {
                let ids = [];
                for (const itemE of e) {
                  ids.push(itemE?.value);
                }
                setIdUnidades(ids);
              }}
              styles={colourStyles}
            />
          </Label>
        </div>
        <div className="row">
          <p className="col s12 m12 l12 text-separador">
            Selecione o Tipo e Marque os Acessos do Perfil abaixo:
          </p>
        </div>
        <div className="row">
          {modulos.length > 0 && (
            <GroupAcoordion
              defaultSelect={typeof grupoPerfil?.acessos == "string" ? JSON.parse(grupoPerfil?.acessos) : grupoPerfil?.acessos}
              title={'Acessos '}
              name={'acessos'}
              itens={modulos}
            />
          )}
        </div>
      </Materialize>
    </Container>
  );
}

export default CreateContent;
