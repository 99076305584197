import React, { useEffect, useState } from "react";

import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import { LandPage, Container, Main } from './styles';
import CustomBodyContent from "./CustomBodyContent";
import { Colors } from "~/config/Colors";
import {
  SLEditModal,
  SLCreateModal,
} from "~/pages/Sistema/componentes/SistemaList";
import { useSelector } from "react-redux";
import socketIO from 'socket.io-client';

// const socket = socketIO.connect('http://localhost:3336');

// const ChatBody = ({ messages }) => {
//   // const navigate = useNavigate();

//   const handleLeaveChat = () => {
//     localStorage.removeItem('userName');
//     // navigate('/');
//     window.location.reload();
//   };

//   return (
//     <>
//       <header className="chat__mainHeader">
//         <p>Hangout with Colleagues</p>
//         <button className="leaveChat__btn" onClick={handleLeaveChat}>
//           LEAVE CHAT
//         </button>
//       </header>

//       <div className="message__container">
//         {messages.map((message) =>
//           message.name === localStorage.getItem('userName') ? (
//             <div className="message__chats" key={message.id}>
//               <p className="sender__name">You</p>
//               <div className="message__sender">
//                 <p>{message.text}</p>
//               </div>
//             </div>
//           ) : (
//             <div className="message__chats" key={message.id}>
//               <p>{message.name}</p>
//               <div className="message__recipient">
//                 <p>{message.text}</p>
//               </div>
//             </div>
//           )
//         )}

//         <div className="message__status">
//           <p>Someone is typing...</p>
//         </div>
//       </div>
//     </>
//   );
// };

// const ChatFooter = ({ socket, usuario }) => {
//   const [message, setMessage] = useState('');

//   const handleSendMessage = (e) => {
//     e.preventDefault();
//     if (message.trim()) {
//       socket.emit('message', {
//         text: message,
//         name: usuario.nome,
//         id: `${socket.id}${Math.random()}`,
//         socketID: socket.id,
//       });
      
//     }
//     setMessage('');
//   };
//   return (
//     <div className="chat__footer">
//       <form className="form" onSubmit={handleSendMessage}>
//         <input
//           type="text"
//           placeholder="Write message"
//           className="message"
//           value={message}
//           onChange={(e) => setMessage(e.target.value)}
//         />
//         <button className="sendBtn">SEND</button>
//       </form>
//     </div>
//   );
// };

// const ChatBar = ({ socket }) => {
//   const [users, setUsers] = useState([]);

//   useEffect(() => {
//     socket.on('newUserResponse', (data) => setUsers(data));
//   }, [socket, users]);

//   return (
//     <div className="chat__sidebar">
//       <h2>Open Chat</h2>
//       <div>
//         <h4 className="chat__header">ACTIVE USERS</h4>
//         <div className="chat__users">
//           {users.map((user) => (
//             <p key={user.socketID}>{user.nome}</p>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };
// console.log(socket);

export default function CentroCusto() {
  const [data, setData] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [mountedData, setMountedData] = useState();
  const [loadedData, setLoadedData] = useState(false);
  const { perfil, idUnidades, controle } = useSelector(state => state.auth);
  const [messages, setMessages] = useState([]);
  
  useEffect(() => {
    setLoadedData(false);
  }, [perfil]);

  useEffect(() => {
    async function handleRequests() {
      api
        .get('/signed/geral/acessos/check')
        .then(response => setModulos(response.data));
    }

    handleRequests();
  }, []);

  useEffect(() => {
    api.get('/signed/cliente/administracao/organizacao/unidades').then(response => {
      let lista = [];
      for (const item of idUnidades) {
        const itemFind = response?.data?.find((o) => o?.id == item)
        itemFind && lista.push({  value: itemFind?.id, label: itemFind?.nome, color: "#00B8D9"});
      }
      setUnidades(lista);
    });
  }, []);

  return (
    <Main>
      <LandPage>
        {/* <div className="chat">
          <ChatBar socket={socket} />
          <div className="chat__main">
            <ChatBody messages={messages} />
            <ChatFooter socket={socket} usuario={usuario} />
          </div>
        </div> */}
        <div className="content">
          <Container
            requests={{
              findAll: () =>
                api.get('/signed/cliente/gestorservico/gerenciamento/centroCustos'),
              findOne: thisData =>
                api.get('/signed/cliente/gestorservico/gerenciamento/centroCusto', {
                  params: { id: thisData.id },
                }),
              deleteOne: thisData =>
                api.delete('/signed/cliente/gestorservico/gerenciamento/centroCusto', {
                  data: { id: thisData.id },
                }),
            }}
            setter={setData}
            setLoaded={setLoadedData}
            loaded={loadedData}
            informationPage={{
              title: 'Centro de Custo',
              subTitle: "Adiocione os Centro de Custo",
              icone: 'submenu-centrocusto',
            }}
            tabFilter={{
              initial: { ativo: true },
              tabAtivo: 0,
              list: [
                {
                  title: 'Ativo',
                  value: { ativo: true },
                },
                {
                  title: 'Desativado',
                  value: { ativo: false },
                },
              ],
            }}
            pageSize={8}
            inputs={[
              { filter: 'titulo', label: 'Titulo' },
              { filter: 'id', label: 'Id' },
            ]}
            selects={[
              {
                list: unidades,
                value: "value",
                label: "label",
                outLabel: "Todas Unidades",
                filter: "unidades.id",
              },
            ]}
          >
            <SLEditModal title="Atualizar Centro de Custo" setter={setMountedData}>
              {mountedData && modulos && (
                <CreateEditContent {...{ modulos, mountedData, idUnidades, controle, unidades }} />
              )}
            </SLEditModal>

            <SLCreateModal title="Adicionar Centro de Custo">
              {modulos && <CreateEditContent {...{ modulos, idUnidades, controle, unidades }} />}
            </SLCreateModal>

            <CustomBodyContent {...{ data }} />
          </Container>
        </div>
      </LandPage>
    </Main>
  );
}
