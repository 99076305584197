import styled from "styled-components";
import Button from "~/componentes/Button";
import { Colors } from "~/config/Colors";

export const Pages = styled.div`
  display: flex;
  align-items: center;
  min-width: 20.6em;
  justify-content: flex-start;
  background: ${Colors.Color1};
  padding: 0.4em 0.6em;
  border-radius: 1em;
  
  .Pagination_controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .pag-number {
      background: ${Colors.Color2};
      margin: 0 0.5em;
      border-radius: 0.5em;
    }
  }

  .Pagination_p_pageSummary {
    background: ${Colors.Color1};
    border-radius: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 2.25em;
    padding: 0 1em;

    color: ${Colors.Color7};
    margin-left: 0.5em;
    min-width: ${(props) =>
    props.summarydigits > 2 ? `${props.summarydigits * 0.4 + 3.6}em` : "4.4em"};
    font-size: 0.75em;

    ${(props) => props.mobile && "text-align: center;"}
  }
`;

export const PageButtonNumber = styled(Button)`
  position: relative;
  background: ${Colors.Color13};
  border: none;
  font-size: 0.68em;
  font-height: 800;
  padding: 0.3em 0.5em;
  margin-left: 0em;
  border-radius: 0.5em;
  transition: 1s;
  color: ${Colors.Color2};

  :hover span {
    display: block;
    background: ${Colors.Color13};
    width: 100%;
    height: 100%;
    margin-left: -0.7em;
    margin-top: -0.1em;
    border-radius: 0.5em;
  }

  p {
    position: relative;
  }
`;

export const PageButtonIcon = styled(Button)`
  background: ${Colors.Color1};
  width: 1em;
  height: 1em;
  border-radius: 0.3em;
  border: none;
  color: ${Colors.Color7};
  font-size: 1.2em !important;

  :hover {
    background: ${Colors.Color10};
    color: ${Colors.Color7};
  }
`;
