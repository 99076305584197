import React, { useEffect, useState } from "react";
import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import { LandPage, Container, Main } from './styles';
import CustomBodyContent from "./CustomBodyContent";
import { Colors } from "~/config/Colors";
import {
  SLEditModal,
  SLCreateModal,
} from "~/pages/Sistema/componentes/SistemaList";
import { useSelector } from "react-redux";

export default function Servicos() {
  const [data, setData] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [mountedData, setMountedData] = useState();
  const [loadedData, setLoadedData] = useState(false);
  const { perfil, idUnidades, controle } = useSelector(state => state.auth);

  useEffect(() => {
    setLoadedData(false);
  }, [perfil]);

  useEffect(() => {
    async function handleRequests() {
      api
        .get('/signed/geral/acessos/check')
        .then(response => setModulos(response.data));
    }

    handleRequests();
  }, []);

  useEffect(() => {
    api.get('/signed/cliente/administracao/organizacao/unidades', { params: { centroCusto: "S" } }).then(response => {
      let lista = [];
      for (const item of idUnidades) {
        const itemFind = response?.data?.find((o) => o?.id == item);

        if (itemFind) {
          let listaCC = [];
          for (const itemCC of itemFind?.centroCustos) {
            listaCC.push({ value: itemCC?.id, label: itemCC?.titulo, color: "#00B8D9" });
          }

          lista.push({ value: itemFind?.id, label: itemFind?.nome, centroCustos: listaCC, color: "#00B8D9" });
        }
      }
      
      setUnidades(lista);
    });
  }, []);

  return (
    <Main>
      <LandPage>
        <div className="content">
          <Container
            requests={{
              findAll: () =>
                api.get('/signed/cliente/gestorservico/gerenciamento/servicos'),
              findOne: thisData =>
                api.get('/signed/cliente/gestorservico/gerenciamento/servico', {
                  params: { id: thisData.id },
                }),
              deleteOne: thisData =>
                api.delete('/signed/cliente/gestorservico/gerenciamento/servico', {
                  data: { id: thisData.id },
                }),
            }}
            setter={setData}
            setLoaded={setLoadedData}
            loaded={loadedData}
            informationPage={{
              title: 'Turnos',
              subTitle: "Adiocione os Turnos",
              icone: 'submenu-servicos',
            }}
            tabFilter={{
              initial: { ativo: true },
              tabAtivo: 0,
              list: [
                {
                  corDestaque: Colors?.Color4,
                  corFundo: Colors?.Color8,
                  title: 'Ativo',
                  value: { ativo: true },
                },
                {
                  corDestaque: localStorage.getItem("tema") == "E" ? '#9A3636' : '#e68282',
                  corFundo: Colors?.Color8,
                  title: 'Desativado',
                  value: { ativo: false },
                },
              ],
            }}
            pageSize={8}
            inputs={[
              { filter: 'titulo', label: 'Titulo' },
              { filter: 'id', label: 'Id' },
            ]}
            selects={[
              {
                list: unidades,
                value: "value",
                label: "label",
                outLabel: "Todas Unidades",
                filter: "unidades.id",
              },
            ]}
          >
            <SLEditModal title="Atualizar Turno" setter={setMountedData}>
              {mountedData && modulos && (
                <CreateEditContent {...{ modulos, mountedData, controle, unidades }} />
              )}
            </SLEditModal>

            <SLCreateModal title="Adicionar Turno">
              {modulos && <CreateEditContent {...{ modulos, controle, unidades }} />}
            </SLCreateModal>

            <CustomBodyContent {...{ data }} />
          </Container>
        </div>
      </LandPage>
    </Main>
  );
}
