import React, { useEffect, useState } from "react";
import api from "~/services/api";
import { Label, Input, Select as SelectCF } from "~/componentes/CustomForm";
import { Colors } from "~/config/Colors";
import Select, { StylesConfig } from 'react-select';

import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import ItensCentroCusto from "../ItensCentroCusto";
import ItensUnidades from "../ItensUnidades";

function CreateEditContent({ mountedData, idUnidades, controle, unidades }) {
  const [ativo, setAtivo] = useState(!mountedData ? "true" : mountedData?.ativo ? "true" : "false");
  const [centroCustos, setCentroCustos] = useState([]);
  const [itens, setItens] = useState(mountedData?.unidades || []);
  const [idsUnidades, setIdsUnidades] = useState(idUnidades);
  const [idUnidadesDefault, setIdUnidadesDefault] = useState([]);

  useEffect(() => {
    api
      .get('/signed/cliente/gestorservico/gerenciamento/centroCustos')
      .then(response => setCentroCustos(response.data));
  }, []);

  useEffect(() => {
    if (mountedData) {
      let lista = [];
      let lista2 = [];

      if (mountedData?.unidades?.length > 0) {
        for (const item of mountedData?.unidades) {
          lista.push({  value: item?.id, label: item?.nome, color: "#00B8D9"});
          lista2.push(item?.id);
        }
      }

      setIdUnidadesDefault(lista);
      setIdsUnidades(lista2);
    } else {
      setIdUnidadesDefault(unidades);
    }
  }, [mountedData, unidades]);
  
  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let thisData = {
          ...data,
          ...(mountedData?.id ? { id: mountedData?.id } : {}),
          itens,
          ativo: ativo === "true" ? true : false,
          idUnidades: idsUnidades,
        }
        let response;
        if (mountedData?.id)
          response = await api.put("/signed/cliente/gestorservico/gerenciamento/servico", thisData);
        else
          response = await api.post("/signed/cliente/gestorservico/gerenciamento/servico", thisData);

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${mountedData?.id ? "atualizar" : "cadastrar"} esse Turno, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja ${mountedData?.id ? "atualizar" : "cadastrar"} esse Turno?`,
        successModal: `Turno ${mountedData?.id ? "atualizado" : "cadastrado"} com sucesso!`,
      }
    );
  }

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? Colors.Color2
          : undefined,
        color: Colors.Color7,
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : Colors.Color2
            : undefined,
        },
      };
    },
  };

  return (
    <Container onSubmit={handleSubmit} defaultScroll={true}>
      <Materialize className="materialize">
        <div className="row header">
          <p className="h-descricao">Preencha as informações corretamente, e se atente ao (*) pois são campos obrigatórios</p>
        </div>
        <div className="row">
          <Label title="Título *" className="col s6 m6 l6">
            <Input name="titulo" required="true" defaultValue={mountedData?.titulo} />
          </Label>
          <Label title="Hora Inicial *" className="col s2 m2 l2">
            <Input type="time" required="true" name="horaIni" defaultValue={mountedData?.horaIni} />
          </Label>
          <Label title="Hora Final *" className="col s2 m2 l2">
            <Input type="time" required="true" name="horaFim" defaultValue={mountedData?.horaFim} />
          </Label>
          <Label title="Ativo *" className="col s2 m2 l2">
            <SelectCF
              isString
              list={{ list: [{ id: "true", titulo: "Sim" }, { id: "false", titulo: "Não" }], value: "id", label: "titulo" }}
              name="ativo"
              defaultValue={mountedData?.ativo?.toString()}
              onChange={(e) => {
                const v = e.currentTarget.value || "true";
                setAtivo(v);
              }}
            />
          </Label>
        </div>
        <div className="row">
          <div className="col s12 m12 l12">
            {/* <ItensCentroCusto setter={setItens} defaultData={itens || []} /> */}
            <ItensUnidades setter={setItens} data={itens || []} defaultData={itens || []} unidades={unidades} />
          </div>
        </div>
      </Materialize>
    </Container>
  );
}

export default CreateEditContent;
