import { store } from "../store";
import { errorToggle } from "~/store/modules/errorModal/actions";
import { loadingToggle } from "~/store/modules/loadingModal/actions";
import { succesToggle } from "~/store/modules/successModal/actions";

/**
 * @param {Function} request Aceita uma requisição que ativará o loading enquanto estiver sendo processada.
 * @param {Object} options Configura o comportamento padrão do modal.
 * @param {any} options.successModal Padrão true. Se false não mostrará modal de sucesso, se String, informará um texto para ser mostrado.
 * @param {Object} options.errorModal Padrão true. Recebe um boolean, string ou objecto de configuração. Se string, será mostrado a mensagem
 *  informada, se for true, será usado o erro para buscar por um padrão de erro do Youch, retornando a mensagem
 *  contida no primeiro erro que tenha como true a chave errorHandled.
 * @param {string} options.errorModal.defaultMessage Caso seja seja informado, o errorModal buscará pelo erro tratado, caso não haja, será retornado essa mensagem no lugar da padrão.
 */
export default async function ShowLoading(request, options) {
  const dispatch = store.dispatch;
  const { successModal = true, errorModal = true, carregando = true } = options || {};
  
  if (carregando) {
    dispatch(loadingToggle());
  }
  try {
    const response = await request();

    if (carregando) {
      dispatch(loadingToggle());
    }
    
    if (successModal && response?.status === 200) {
      dispatch(succesToggle(successModal));
    }
    return response;
  } catch (err) {
    if (carregando) {
      dispatch(loadingToggle());
    }

    if (errorModal) {
      let thisErrorMessage =
        typeof errorModal === "string" ? errorModal : err?.response || err;

      dispatch(errorToggle(thisErrorMessage, errorModal?.defaultMessage));
    }
    return err;
  }
}
