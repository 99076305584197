import React, { useContext } from "react";
import Label from "~/componentes/CustomForm/Label";
import { Input, Select as SelectCF } from "~/componentes/CustomForm";
import Select, { StylesConfig } from 'react-select';
import { Colors } from "~/config/Colors";

import ColoredButton from "~/componentes/Button/Styles/ColoredButton";
import SingleValueList from "~/componentes/SingleValueList";
import sistemaListStore from "../../../../../../componentes/SistemaList/ThisMainComponent/thisStore";
import { useState } from "react";
import { useEffect } from "react";
import api from "~/services/api";
import FindKeyValue from "~/utils/FindKeyValue";

import { Container } from "./styles";
import IconButton from "~/componentes/Button/Styles/IconButton";

function ItensSatisfacaoList({ defaultData, setter, data, disabled, unidades }) {
  const [dfAvailable, setDfAvailable] = useState([]);
  const [responsavel, setResponsavel] = useState();
  const [dfSelected, setDfSelected] = useState([]);
  const [upgradeSetter, setUpgradeSetter] = useState(false);
  const { state } = useContext(sistemaListStore);
  const { edit, editShow } = state?.modal || {};

  useEffect(() => {
    if (unidades?.length > 0) {
      let lista = [];

      for (const itemDF of unidades) {
        const findList = data?.find((e) => e?.id == itemDF?.value);
        if (!findList) {
          lista.push({ id: itemDF?.value, titulo: itemDF?.label, centroCustos: itemDF?.centroCustos, defaultCentroCustos: [], idCentroCustos: [] });
        }
      }

      setDfAvailable(lista);

      if (data?.length == 0) {
        setter(lista);
      }
    }

  }, [unidades]);

  useEffect(() => {
    if (data?.length > 0 && unidades?.length > 0) {
      const findList = data.find((e) => e?.centroCustos?.length == 0);
      if (findList) {
        let listaCC = [];
        for (const itemDF of data) {
          const findItem = unidades.find((e) => e?.value == itemDF?.id);
          findItem && listaCC.push({ ...itemDF, centroCustos: findItem?.centroCustos });
        }
  
        setter(listaCC);
      }
    }

  }, [data]);

  function handleAdd() {
    if (responsavel?.id) {
      setter((prev) => [...prev, responsavel]);

      setDfAvailable((prev) => {
        const newPrev = [...prev];
        const checkIndex = prev.findIndex((e) => e?.id === responsavel?.id);

        if (checkIndex >= 0) {
          newPrev.splice(checkIndex, 1);
        }

        return newPrev;
      });
      setResponsavel({});
    }
  }

  function handleRemove(data) {
    if (!disabled) {
      setter((prev) => {
        const newPrev = [...prev];
        const checkIndex = prev.findIndex((e) => e?.id === data?.id);

        if (checkIndex >= 0) {
          let lista = [];

          for (const itemDF of dfAvailable) {
            lista.push(itemDF);
          }
          lista.push(prev[checkIndex]);
          
          setDfAvailable(lista);

          newPrev.splice(checkIndex, 1);
        }

        return newPrev;
      });
    }
  }

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? Colors.Color2
          : undefined,
        color: Colors.Color7,
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : Colors.Color2
            : undefined,
        },
      };
    },
  };

  return (
    <Container>
      <Label title="Itens de Satisfação *" />
      {!(editShow && edit) && (
        !disabled && (
          <div className="Etapa_div_addResponsaveis">
            <SelectCF
              list={{ list: dfAvailable, value: "id", label: "titulo" }}
              setList={setResponsavel}
              firstOption
              defaultValue={responsavel?.id}
              disabled={disabled}
            />
            <ColoredButton small blueLayout type="button" onClick={handleAdd}>
              Adicionar
            </ColoredButton>
          </div>
        )
      )}
      <div className="list-itens">
        {
          data?.map((item) => (
            <div className="item row">
              <div className="col s1 m1 l1 item-icon no-margin">
                <IconButton
                  className="SingleValueList_div_IconButton"
                  materialIcon={{ close: true }}
                  onClick={() => handleRemove(item)}
                />
              </div>
              <div className="col s4 m4 l4 no-margin">
                <p>{FindKeyValue("titulo", item)}</p>
              </div>
              <Label title="Centro de Custo:" className="col s7 m7 l7 align-left no-margin">
                <Select
                  value={item?.defaultCentroCustos}
                  isMulti
                  name="colors"
                  options={item?.centroCustos}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Centro de Custo"
                  onChange={(list) => {
                    let ids = [];
                    for (const itemE of list) {
                      ids.push(itemE?.value);
                    }
                    
                    setter((prev) => {
                      const newPrev = [...prev];
                      const checkIndex = prev.findIndex((e) => e?.id === item?.id);

                      newPrev[checkIndex].defaultCentroCustos = list;
                      newPrev[checkIndex].idCentroCustos = ids;

                      return newPrev;
                    });
                  }}
                  styles={colourStyles}
                />
              </Label>
            </div>
          ))
        }
      </div>
    </Container>
  );
}

export default ItensSatisfacaoList;
